import React from "react";
import { withAuthentication } from "../../hoc/withAuthentication";
import Consts from "../../config/consts";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ContentArea from "../../components/contentArea";
import ScreenLoad from "../../components/screenLoad";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class Feed extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
        }

        componentDidMount() {}

        render() {
            return (
                <main>
                    <ScreenLoad />
                </main>
            );
        }
    }
);
